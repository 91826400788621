import { type MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'
import { ExternalLink } from 'lucide-react'
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '#app/components/ui/accordion.js'
// import { BentoCard, BentoGrid } from '#app/components/ui/bento-grid'
import { Button } from '#app/components/ui/button.js'
import RetroGrid from '#app/components/ui/retro-grid.js'

// const features = [
// 	{
// 		Icon: () => <div className="text-4xl">🎯</div>,
// 		name: 'discover anomalous cognition',
// 		description:
// 			'FREE practice tools for explorers of the mental martial arts: remote viewing.',
// 		href: '/',
// 		cta: 'What is anomalous cognition?',
// 		background: (
// 			<img
// 				className="group absolute -top-20 opacity-60 blur transition-all group-hover:opacity-100 group-hover:blur-none"
// 				alt="Anomalous Cognition Training"
// 				src="/img/dojo_scenes/dojo_scene_targets_1.png"
// 			/>
// 		),
// 		className: 'md:row-start-1 group md:row-end-2 md:col-start-2 md:col-end-3',
// 	},
// 	{
// 		Icon: BotMessageSquare,
// 		name: 'monitor agents',
// 		description:
// 			'Train in a digital dojo with AI copilots to help you level up.',
// 		href: '/',
// 		cta: 'Learn more',
// 		background: (
// 			<img
// 				className="group absolute -top-10 opacity-60 blur transition-all group-hover:-top-5 group-hover:scale-75 group-hover:opacity-100 group-hover:blur-none"
// 				alt="Monitor Agent"
// 				src="/img/constructs/beige-robed-monk-on-stark-white-background-in-karate-pose-wearing-daft-punk-helmet-full-body-visibl.png"
// 			/>
// 		),
// 		className: 'md:row-start-1 group md:row-end-2 md:col-start-3 md:col-end-4',
// 	},
// ]

export const meta: MetaFunction = () => [{ title: 'intuizen' }]

export default function Index() {
	return (
		<main className=" relative bg-orange-50">
			<div className="relative flex flex-col items-center justify-center bg-gradient-to-b from-orange-100 to-orange-50 bg-bottom p-8 md:p-10">
				<RetroGrid />
				<img
					src="/img/symbol/ntu.svg"
					alt="intuizen symbol"
					className="z-10 w-24"
				/>
				<h1
					data-heading
					className="z-10 my-4 animate-slide-top text-center text-6xl font-semibold [animation-delay:0.1s] [animation-fill-mode:backwards] md:text-5xl xl:mt-4 xl:text-6xl xl:[animation-delay:0.2s] xl:[animation-fill-mode:backwards]"
				>
					intui
					<span className="text-brand-gradient">zen</span>
				</h1>
				<p
					id="tagline"
					className="relative flex flex-col text-center text-3xl md:text-4xl"
				>
					digital dojo
					<span className="text-base">for the</span>
					mental martial arts
				</p>
				<Button id="enter-dojo" asChild className="z-10 mt-4">
					<Link to="/targets">⛩︎ Start Training Now</Link>
				</Button>
			</div>
			{/* <BentoGrid className="container grid-cols-1 gap-4 md:grid-cols-4">
				{features.map(feature => (
					<BentoCard key={feature.name} {...feature} />
				))}
			</BentoGrid> */}

			<div className="mx-auto mt-16 w-full md:w-96">
				<h2 className="mb-8 text-center text-3xl font-bold">
					Questions and Answers
				</h2>
				<Accordion type="single" collapsible className="w-full p-8 md:p-4">
					<AccordionItem value="item-1">
						<AccordionTrigger>Who created intuizen?</AccordionTrigger>
						<AccordionContent>
							I'm{' '}
							<a
								rel="noopener noreferrer"
								target="_blank"
								className="underline"
								href="https://x.com/myconull"
							>
								myconull
							</a>
							, software artist, dev and Matrix fan. I also built and
							open-sourced{' '}
							<a
								rel="noopener noreferrer"
								target="_blank"
								className="underline"
								href="https://cognactive.net"
							>
								cognactive.
							</a>
						</AccordionContent>
					</AccordionItem>
					<AccordionItem value="item-2">
						<AccordionTrigger>Why did you create it?</AccordionTrigger>
						<AccordionContent>
							To massively accelerate the research of consciousness. What is it
							and how does it work. The phenomenon of remote viewing, psi and
							precognition should be explored further. Also, I wanted to create
							something cool while practicing with generative AI.
						</AccordionContent>
					</AccordionItem>
					<AccordionItem value="item-4">
						<AccordionTrigger>What problem does this solve?</AccordionTrigger>
						<AccordionContent>
							<p>Not sure yet, still experimenting, but here are some ideas:</p>
							<ul className="list-disc pl-5">
								<li>
									Realtime feedback helps close the feedback loop faster with a
									digital training monitor
								</li>
								<li>
									Telepathic interference is eliminated with a digital monitor
								</li>
								<li>
									<a
										rel="noopener noreferrer"
										target="_blank"
										className="underline"
										href="https://www.irva.org/docs/public/bibliography/pdfs/ASSOCIATIVE_REMOTE_VIEWING_PROJECTS_ASSE.pdf"
									>
										Inter-rate reliability{' '}
										<ExternalLink className="inline-block h-4 w-4" />
									</a>{' '}
									is replaced instead by large languagel model reasoning.
								</li>
							</ul>
							There's more, see my PsiFest 2023 presentation.
						</AccordionContent>
					</AccordionItem>
				</Accordion>
			</div>

			{/* <div className="flex flex-col items-center text-center">
				<h2 className="text-4xl font-bold">
					Your personalized <span className="text-brand-gradient">sense</span>ai
				</h2>
				<p data-paragraph className="p-16 text-2xl xl:leading-10">
					A digital senseai master will guide you on this journey. The training
					curriculum is personalized using AI to teach you about the martial art
					of{' '}
					<span className="text-brand-gradient bg-gradient-to-r">
						subconscious-to-conscious
					</span>{' '}
					communication, so you can experience activities in the distance or
					future.
				</p>
			</div>

			<div className="flex flex-col items-center text-center">
				<h2 className="text-4xl font-bold">
					World of <span className="">intui</span>
					<span className="text-brand-gradient">zards</span>
				</h2>
				<p className="p-16 text-2xl xl:leading-10">
					Practice daily to level up to mastery in an immersive and interactive{' '}
					<span className="text-brand-gradient bg-gradient-to-r">
						metagame experience
					</span>
					. Train and track your practice with mock tasking and evaluate
					instantly with AI-assisted feedback.
				</p>
			</div>

			<div className="flex flex-col items-center text-center">
				<h2 className="text-4xl font-bold">
					Your passport to the <span className="">intui</span>
					<span className="text-brand-gradient">net</span>
				</h2>
				<p className="p-16 text-2xl xl:leading-10">
					intui<span className="text-brand-gradient">zen</span> is your passport
					to a network of fellow int
					<span className="text-brand-gradient font-semibold">uizards</span>.
					Team up or go solo and apply your intuition to real-world tasking to
					help innovation, solve problems or expand knowledge.
				</p>
			</div> */}
		</main>
	)
}
